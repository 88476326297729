import { useEffect, useState } from "react";
import { useAgnosticPostMutation } from "@/lib/services/endpoints/newAPI/agnostic";
import { menuMap, subSystemToModulesMap } from '@/lib/maps'

export function useEnsurePrivileges() {
  const [result, setResult] = useState<any>(null);
  const [doEnsure, setDoEnsure] = useState<boolean>(false);

  const [backend] = useAgnosticPostMutation();
  useEffect(() => {
    if (doEnsure) {
      //Ensure all critical privileges needed are available. For now, maintain a list here:
      const ps = [
        { name: "pages.building",       desc: "Access to generalised building page" },
        { name: "documents.writeRoot",  desc: "Allow addition/edit of documents in document root folder" },
        { name: "cafm.engineer",        desc: "A user with this privilege is considered a CAFM Engineer" },
        { name: "cafm.cloneJobPlans",   desc: "Allow copy of Job Plans in CAFM Module page" },
        { name: "auditTracker.manageConcerns", desc: "Users can manage Audit Tracker concerns on their buildings" }
      ];

      //Add (auto) page privileges:
      const addPerm = function (m) {
        var perm = 'pages.' + String(m.href).replaceAll(/\//g, '.');
        perm = perm.replaceAll(/[\.]+/g, '.');
        const desc = "Access to page '" + (m.label ? m.label : m.name) + "'";
        if (!ps.find((p) => { p.name == perm })) {
          ps.push({ name: perm, desc: desc });
        }

        if (m.items) {
          m.items.forEach(addPerm);
        }
      };

      const mm = menuMap();
      Object.keys(mm).forEach((mk) => {
        const topMenu = mm[mk];
        topMenu.forEach(addPerm);
      });

      subSystemToModulesMap?.forEach((topMenu) => {
        addPerm(topMenu.props);
        if (topMenu.items) {
          topMenu.items.forEach(addPerm);
        }
      }
      );

      backend({ url: `ensurePrivs`, body: { privileges: ps } }).then(() => { /* No action necessary*/ });
    }
  }, [doEnsure, backend]);

  return {
    ensurePrivileges: () => {
      setDoEnsure(true);
    }, result
  };
}

