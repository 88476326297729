import * as menuIcons from "@/components/Layouts/Navigation/icons/index";
import { IMenu, IPage } from "./interfaces";
import { MenuIconProps } from "./types";
import { RiQuestionnaireLine } from 'react-icons/ri'
import { FaFileInvoice, FaUsers, FaUserLock } from 'react-icons/fa'
import { BsOption } from 'react-icons/bs'
import { RiUserSettingsFill, RiListSettingsLine } from 'react-icons/ri'
import { TbUsers, } from 'react-icons/tb'
import { BsListColumnsReverse, } from 'react-icons/bs'
import { CgTemplate } from 'react-icons/cg'
import { MdOutlineBadge, MdOutlineContactSupport } from 'react-icons/md'
import { TbTruckDelivery } from 'react-icons/tb'
import { FaUsersBetweenLines } from 'react-icons/fa6'
import { BiBook } from 'react-icons/bi'
import { AiOutlineClockCircle, AiOutlineAudit } from 'react-icons/ai'
import { LuNetwork } from "react-icons/lu";
import { FaNetworkWired } from "react-icons/fa";

const { DocumentsIcon, RiskRadarIcon, ShiftRotaIcon, BuildingsIcon, AdminIcon, ClientsIcon, SubsystemsIcon, AsbestosIcon, MaintenanceCalendar, CafmIcon, DlogsIcon, HazardIcon, MainPageIcon }
  = menuIcons;

export type IMenuType = {

  mainItem: IMenu[],
  staticItems: IMenu[],
  moduleItems?: IMenu[],
  buildingItems?: IMenu[],
}

export enum CRUD {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export const securePages: IPage[] = [
  'users', 'globalUserRoles', 'clients', 'suppliers', 'buildings', 'globalUserRoles', 'clientUserRoles'
];

export const safePages: string[] = [
  'dashboard',
  'building',
  'matterport',
  'floor2d',
]

export const menuMap = (): IMenuType => {

  return {
    'mainItem': [
      {
        href: "/dashboard",
        Icon: MainPageIcon.default,
        label: "Dashboard",
        items: []
      },
    ],
    'moduleItems': [
      {
        href: "/modules",
        Icon: SubsystemsIcon.default,
        label: "Modules",
        items: [],
        allowFiltering: true
      },
    ],

    'staticItems': [

      {
        href: "/admin",
        Icon: AdminIcon.default,
        label: "Admin",
        items: [
          {
            href: "/admin/users/list",
            Icon: FaUsers,
            label: "Users", breadcrumbLink: true,
            privilege: {
              page: 'users',
              crudRules: [CRUD.READ] //render menu item if
            }
          },
          {
            href: "/admin/roles/list",
            Icon: FaUserLock,
            label: "Client Roles", breadcrumbLink: true,
            privilege: {
              // page: 'roles',
              page: 'role',
              crudRules: [CRUD.READ]
            }
          },
          {
            href: "/admin/roles/global/list",
            Icon: RiUserSettingsFill,
            label: "Global Roles", breadcrumbLink: true,
            privilege: {
              page: 'globalUserRoles',
              crudRules: [CRUD.READ] //render menu item if
            }
          },

          {
            href: "/admin/systemDefaults",
            Icon: RiListSettingsLine,
            label: "Global System Defaults",
            breadcrumbLink: true,
            privilege: {
              page: 'globalSystemDefaults',
              crudRules: [CRUD.READ] //render menu item if
            }
          },

          {
            href: "/admin/clients/list",
            Icon: ClientsIcon.default,
            label: "Clients", breadcrumbLink: true,
            privilege: {
              page: 'clients',
              crudRules: [CRUD.READ] //render menu item if
            }
          },
          {
            href: "/admin/suppliers/list",
            Icon: TbTruckDelivery,
            label: "Suppliers", breadcrumbLink: true,
            privilege: {
              page: 'suppliers',
              crudRules: [CRUD.READ] //render menu item if
            }
          },
          {
            href: "/admin/suppliers/users",
            Icon: FaUsersBetweenLines,
            label: "Supplier users", breadcrumbLink: true,
            privilege: {
              page: 'suppliers',
              crudRules: [CRUD.READ] //render menu item if
            }
          },

          {
            href: "/admin/contracts/list",
            Icon: TbUsers,
            label: "Contracts", breadcrumbLink: true,
            privilege: {
              page: 'contracts',
              crudRules: ['list'] //render menu item if
            }
          },
          {
            href: "/admin/buildings/list",
            Icon: BuildingsIcon.default,
            label: "Buildings", breadcrumbLink: true,
            privilege: {
              page: 'buildings',
              crudRules: [CRUD.READ] //render menu item if
            }
          },

          {
            href: "/floor/list",
            Icon: BuildingsIcon.default,
            label: "Floors", breadcrumbLink: true,
            hide: true
          },

          // {
          //   href: "/admin/assets",
          //   Icon: BsListColumnsReverse,
          //   label: "Assets",
          //   breadcrumbLink: true
          // },

          {
            href: "/admin/qrFreeLinks",
            Icon: CgTemplate,
            label: "QR Links",
            breadcrumbLink: true,
            privilege: {
              page: 'qrfreelinks',
              crudRules: [CRUD.READ] //render menu item if
            }
          },


          {
            href: "/admin/box",
            Icon: ShiftRotaIcon.default,
            label: "Analytics",
            open: false,
            moduleSettings: true,
            moduleName: 'Box',
            items: [
              {
                href: "/admin/box/devices",
                label: "Devices", breadcrumbLink: true
              },
              {
                href: "/admin/box/deviceClasses",
                label: "Device Classes", breadcrumbLink: true
              },

              {
                href: "/admin/box/deviceIcons",
                label: "Device Icons", breadcrumbLink: true
              },
              {
                href: "/admin/box/utilities",
                label: "Utilities", breadcrumbLink: true
              },
              {
                href: "/admin/box/instances",
                label: "Box instances", breadcrumbLink: true
              },

              {
                href: "/admin/box/alarms",
                label: "Alarms", breadcrumbLink: true
              },

              {
                href: "/admin/box/criticalAlarmHistory",
                label: "Critical Alarm History", breadcrumbLink: true
              },

            ]
          },
          {
            href: "/admin/bms",
            Icon: FaNetworkWired,
            label: "BMS",
            open: false,
            moduleSettings: true,
            moduleName: 'BMS'
          },


          {
            href: "/admin/asset",
            Icon: BsListColumnsReverse,
            label: "Assets",
            open: false,
            moduleSettings: true,
            moduleName: 'Assets',
            items: [
              { href: "/admin/assets/levelOne", label: "Category Level 1", breadcrumbLink: true },
              { href: "/admin/assets/levelTwo", label: "Category Level 2", breadcrumbLink: true },
              { href: "/admin/assets/levelThree", label: "Category Level 3", breadcrumbLink: true, },
              { href: "/admin/assets/levelFour", label: "Category Level 4", breadcrumbLink: true },
              { href: "/admin/assets/tags", label: "Tags", breadcrumbLink: true },
              { href: "/admin/assets/sfg", label: "SFG data", breadcrumbLink: true },
            ]
          },

          {
            href: "/admin/cafm",
            Icon: CafmIcon.default,
            label: "CAFM",
            open: false,
            moduleSettings: true,
            moduleName: 'CAFM',
            items: [
              { href: "/admin/cafm/jobPlans", label: "Job Plans Admin", breadcrumbLink: true },
              { href: "/admin/cafm/types", label: "Types" },
              { href: "/admin/cafm/dashboardData", label: "Dashboard Data Config" }
            ]
          },
          {
            href: "/admin/auditTracker",
            Icon: AiOutlineAudit,
            label: "AuditTracker",
            open: false,
            moduleSettings: true,
            moduleName: 'Audit Tracker',
            items: [
              { href: "/admin/auditTracker/reviewItems", label: "Review Items", breadcrumbLink: true }
            ]
          },
          {
            href: "/admin/kpiSheets",
            Icon: DocumentsIcon.default,
            label: "KPI Sheets",
            open: false,
            moduleSettings: true,
            moduleName: 'Kpi',
            items: [
              { href: "/admin/kpiSheets/kpiTemplates", label: "KPI Templates", breadcrumbLink: true },
            ]
          },
          {
            href: "/admin/mobilisationPlanner",
            Icon: DocumentsIcon.default,
            label: "Mobilisation Planner",
            open: false,
            moduleSettings: true,
            moduleName: 'Mobilisation Planner',
            items: [
              { href: "/admin/mobilisationPlanner/globalTemplates", label: "Global Templates", breadcrumbLink: true },
              { href: "/admin/mobilisationPlanner/clientTemplates", label: "Client Templates", breadcrumbLink: true },
            ]
          },
          {
            href: "/admin/sops",
            Icon: BsOption,
            label: "SOPs",
            open: false,
            moduleSettings: true,
            moduleName: 'SOPS',
            items: [
              {
                href: "/admin/sops/templates",
                label: "Templates", breadcrumbLink: true
              },
              {
                href: "/admin/sops/types",
                label: "Types", breadcrumbLink: true
              },
            ]
          },



          {
            href: "/admin/hazardReporting",
            Icon: HazardIcon.default,
            label: "Hazard Reporting",
            open: false,
            moduleSettings: true,
            moduleName: 'Hazards'
          },

          {
            href: "/admin/maintenanceCalendar",
            Icon: MaintenanceCalendar.default,
            label: "Maintenance Calendar",
            open: false,
            moduleSettings: true,
            moduleName: 'Ppm',
            items: [
              { href: "/admin/maintenanceCalendar/assets", label: "Assets", breadcrumbLink: true },
              { href: "/admin/maintenanceCalendar/tasks", label: "Tasks", breadcrumbLink: true },
              { href: "/admin/maintenanceCalendar/categoryAssets", label: "Categories", breadcrumbLink: true },
            ]
          },

          {
            href: "/admin/ticketSupport",
            Icon: MdOutlineContactSupport,
            label: "Ticket Support",
            open: false,
            moduleSettings: true,
            moduleName: 'ticketSupport'
          },

          {
            href: "/admin/trainingMatrix",
            Icon: DocumentsIcon.default,
            label: "Training Matrix",
            open: false,
            moduleSettings: true,
            moduleName: 'Tm',
            items: [
              {
                href: "/admin/trainingMatrix/categories",
                label: "Categories",
                breadcrumbLink: true
              },
              {
                href: "/admin/trainingMatrix/globalProgrammes",
                label: "Global programmes",
                breadcrumbLink: true
              },
              {
                href: "/admin/trainingMatrix/programmes",
                label: "Programmes",
                breadcrumbLink: true
              },
              {
                href: "/admin/trainingMatrix/positions",
                label: "Positions",
                breadcrumbLink: true
              },
              {
                href: "/admin/trainingMatrix/personnel",
                label: "Trainees",
                breadcrumbLink: true
              },
            ]
          },
          {
            href: "/admin/quotes",
            Icon: FaFileInvoice,
            label: "Quotes",
            open: false,
            moduleSettings: true,
            moduleName: 'Quote',
            items: [
              {
                href: "/admin/quotes/labours",
                label: "Labours",
                breadcrumbLink: true
              },
              {
                href: "/admin/quotes/materials",
                label: "Materials",
                breadcrumbLink: true
              },
            ]
          },





          {
            href: "/admin/inductions",
            Icon: RiQuestionnaireLine,
            label: "Inductions",
            open: false,
            moduleSettings: true,
            moduleName: 'Inductions',
            items: [
              {
                href: "/admin/inductions/list",
                label: "Inductions",
                breadcrumbLink: true
              },
              {
                href: "/admin/inductions/questionnaires",
                label: "Questionnaires",
                breadcrumbLink: true
              },
              {
                href: "/admin/inductions/questions",
                label: "Question Templates",
                breadcrumbLink: true
              },
              {
                href: "/admin/inductions/categories",
                label: "Categories",
                breadcrumbLink: true
              },
            ]
          },



          {
            href: "/admin/epermits",
            Icon: MdOutlineBadge,
            label: "E Permits",
            open: false,
            moduleSettings: true,
            moduleName: 'E Permits',
            items: [
              {
                href: "/admin/epermits/controlMeasures",
                label: "Control measures",
                breadcrumbLink: true
              },
              {
                href: "/admin/epermits/services",
                label: "Services",
                breadcrumbLink: true
              },
            ]
          },


          {
            href: "/admin/elogbooks",
            Icon: BiBook,
            label: "E Logbooks",
            open: false,
            moduleSettings: true,
            moduleName: 'E Logbooks',
            items: [
              {
                href: "/admin/elogbooks/tasks",
                label: "Task Templates",
                breadcrumbLink: true
              },
              {
                href: "/admin/elogbooks/logbooks",
                label: "Logbook Templates",
                breadcrumbLink: true
              },
              {
                href: "/admin/elogbooks/clientLogbooks",
                label: "Client Logbooks",
                breadcrumbLink: true
              },
            ]
          },

          {
            href: "/admin/warrantyManagement/categories",
            Icon: DocumentsIcon.default,
            label: "Warranty Categories",
            open: false,
            moduleSettings: true,
            moduleName: 'Warranty Documents'
          },

          {
            href: "/admin/wallDashboards/list",
            Icon: DocumentsIcon.default,
            label: "Wall Dashboards",
            open: false,
          },

          {
            href: "/admin/documents",
            Icon: DocumentsIcon.default,
            label: "Documents",
            open: false,
            moduleSettings: true,
            moduleName: 'Documents',
            items: [
              {
                href: "/admin/documents/categories",
                label: "Categories", breadcrumbLink: true
              }
            ]
          },



          {
            href: "/admin/templates",
            Icon: CgTemplate,
            label: "Templates",
            open: false,
            items: [
              // { href: "/admin/templates/notifications", label: "Notifications", breadcrumbLink: true },
              // { href: "/admin/templates/recipients", label: "Recipients", breadcrumbLink: true },
              { href: "/admin/templates/content", label: "Content", breadcrumbLink: true },

              { href: "/admin/templates/categories", label: "Categories", breadcrumbLink: true },
              { href: "/admin/templates/footers", label: "Footers", breadcrumbLink: true },
            ]
          },

        ]
      }
    ],
    'buildingItems': [
      {
        href: "/building",
        Icon: BuildingsIcon.default,
        label: "Buildings",
        items: [],
        allowFiltering: true,
      },
    ],
  }
}


type SubsystemModuleType = {
  name: string,
  props: {
    href: string;
    name: string;
    Icon?: ({ fill }: MenuIconProps) => JSX.Element
  },
  items?: IMenu[]
}

export const subSystemToModulesMap: Array<SubsystemModuleType> = [
  {
    name: 'Riskradar',
    props: {
      href: '/module/risks',
      name: 'Risk Radar',
      Icon: RiskRadarIcon.default,
    }
  },
  {
    name: 'Ppm',
    props: {
      href: '/module/maintenanceCalendar',
      name: 'Maintenance Calendar',
      Icon: MaintenanceCalendar.default,
    }
  },
  {
    name: 'E Logbooks',
    props: {
      href: '/module/elogbooks',
      name: 'E Logbooks',
      Icon: BiBook,
    }
  },
  {
    name: 'Quote',
    props: {
      href: '/module/quotes',
      name: 'Quotes',
      Icon: ShiftRotaIcon.default,
    }
  },
  {
    name: 'Tm',
    props: {
      href: '/module/trainingMatrix',
      name: 'Training Matrix',
      Icon: ShiftRotaIcon.default,
    }
  },
  {
    name: 'Box',
    props: {
      href: '/module/box',
      name: 'Analytics',
      Icon: ShiftRotaIcon.default,
    },
    items: [
      { href: "/module/box/deviceByClass", label: "Devices" },
      { href: "/module/box/floorplans", label: "Floor Plans" },
      { href: "/module/box/spaces", label: "3D Spaces" },
      // { href: "/module/box/visualisations", label: "3D visualisations" },
      { href: "/module/box/comfort", label: "Comfort" },
      { href: "/module/box/energy", label: "Energy" },
      { href: "/module/box/carbonFootprint", label: "Carbon Footprint" },
      { href: "/module/box/acEfficiency", label: "A/C Efficiency" },
      { href: "/module/box/scheduleValidation", label: "Schedule Validation" },
      //{ href: "/module/box/buildingEfficiency",  label: "Building Efficiency" },
      { href: "/module/box/submeters", label: "Sub Meters" },
      { href: "/module/box/alarms", label: "Alarms" },
      { href: "/module/box/analysisReports", label: "Analysis Reports" },
      { href: "/module/box/demandReport", label: "Demand Report" },
      //{ href: "/module/box/zoonex",  label: "Zoonex" },
    ]
  },
  {
    name: 'Documents',
    props: {
      href: '/module/documents',
      name: 'Documents',
      Icon: DocumentsIcon.default,
    },
    items: [
      { href: "/module/documents/siteDocs", label: "Site Documents" },
      { href: "/module/documents/clientDocs", label: "Client Documents" }
    ]
  },
  {
    name: 'Walkaround',
    props: {
      href: '/module/walkaround',
      name: 'Walkaround',
      Icon: ShiftRotaIcon.default,
    },
    items: [
      { href: "/module/walkaround/panel", label: "Panel" },
      { href: "/module/walkaround/forms", label: "Assets and Forms" }
    ]
  },
  {
    name: 'Warranty Documents',
    props: {
      href: '/module/warrantyDocuments',
      name: 'Warranty Documents',
      Icon: DocumentsIcon.default,
    }
  },
  {
    name: 'Hazards',
    props: {
      href: '/module/hazardReporting',
      name: 'Hazard Reporting',
      Icon: HazardIcon.default,
    }
  },
  {
    name: 'SOPS',
    props: {
      href: '/module/sops',
      name: 'SOPS',
      Icon: ShiftRotaIcon.default,
    }
  },
  {
    name: 'CAFM',
    props: {
      href: '/module/cafm',
      name: 'CAFM',
      Icon: CafmIcon.default,
    },
    items: [
      { href: "/module/cafm/jobPlans", label: "Job Plans" },
      { href: "/module/cafm/pm", label: "PM Work Orders" },
      { href: "/module/cafm/rt", label: "RT Work Orders" },
      { href: "/module/cafm/maintenanceCalendar", label: "PM Calendar" },
      { href: "/module/cafm/callCentre", label: "Call Centre" },
      // { href: "/module/cafm/dashboard", label: "Dashboard" } 
    ]
  },
  {
    name: 'Dlogs',
    props: {
      href: '/module/dlogs',
      name: 'Dlogs',
      Icon: DlogsIcon.default,
    },
    items: [
      //{ href: "/module/dlogs/dashboard",  label: "Dashboard" },
      { href: "/module/dlogs/activeDocuments", label: "Active Documents" },
      { href: "/module/dlogs/calendar", label: "Calendar" },
      { href: "/module/dlogs/documentDepository", label: "Document Depository" },
    ]
  },


  {
    name: 'Asbestos',
    props: {
      href: '/module/asbestos',
      name: 'Asbestos',
      Icon: AsbestosIcon.default,
    }
  },
  {
    name: 'Assets',
    props: {
      href: '/module/assets',
      name: 'Assets',
      Icon: BsListColumnsReverse
    },
    items: [
      { href: "/module/assets", label: "Assets" },
      { href: "/module/assets/groups", label: "Groups" },
      // { href: "/module/assets/categories", label: "Categories" }
    ]
  },

  {
    name: 'BMS',
    props: {
      href: '/module/bms',
      name: 'BMS',
      Icon: FaNetworkWired,
    }
  },

  {
    name: 'Rota',
    props: {
      href: '/module/shiftRota',
      name: 'Shift Rota',
      Icon: AiOutlineClockCircle,
    }
  },

  {
    name: 'Kpi',
    props: {
      href: '/module/kpi',
      name: 'KPI Sheets',
      Icon: DocumentsIcon.default,
    }
  },

  {
    name: 'Compliments',
    props: {
      href: '/module/compliments',
      name: 'Compliments & Complaints',
      Icon: DocumentsIcon.default,
    }
  },

  {
    name: 'Audit Tracker',
    props: {
      href: '/module/auditTracker',
      name: 'Audit Sheets',
      Icon: AiOutlineAudit,
    },
    items: [
      { href: "/module/auditTracker/sheets", label: "Sheets" },
      { href: "/module/auditTracker/openConcerns", label: "Open Concerns" }
    ]
  },

  {
    name: 'Inductions',
    props: {
      href: '/module/inductions',
      name: 'My Inductions',
      Icon: RiQuestionnaireLine,
    }
  },
  {
    name: 'E Permits',
    props: {
      href: '/module/epermits',
      name: 'E Permits',
      Icon: MdOutlineBadge,
    },
    items: [
      { href: "/module/epermits/list", label: "Permits" },
      { href: "/module/epermits/emergency", label: "Emergency Permits" },
      { href: "/module/epermits/authorityToAccess", label: "Authority to Access" },
      { href: "/module/epermits/competencyManagement", label: "Competency Management" },
      { href: "/module/epermits/summaryReports", label: "Summary Report" },
      { href: "/module/epermits/rams", label: "RAMS", },
    ]
  },
  {
    name: 'Mobilisation Planner',
    props: {
      href: '/module/mobilisationPlanner',
      name: 'Mobilisation Planner',
      Icon: DocumentsIcon.default,
    },
    items: [
      { href: "/module/mobilisationPlanner", label: "Projects" },
    ]
  },
]

export const protectedPaths: Array<string> = [
  "",
  "dashboards",
  "dashboard",
  "settings",
  "documents",
  "contracts",
  "documents",
  "clients",
  "modules",
  "module",
  "users",
  "boxInstances",
  "floorplan",
  "devices",
  "building",
  "buildings",
  "inductions",
  "epermits",
  "admin"
];


//array of users like API User, Acc Daemon etc
export const protectedUsersIds: Array<string> = [
  '36', //API USER
  '56541', //Acc Daemon
  '14631' //BOX API
]
