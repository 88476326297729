import { IClient, IContractObject, IClientRole, IDeviceClassLoookupField, IBoxDevice, IBoxInstance, IBoxDeviceWithInstance, IDeviceFieldValue, IAlarm, IBuildingObject, IMultiCallItem, IBuildingGroup } from "@/lib/interfaces";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { omniApi, passParams } from "../../omniApi";
import { queryParams } from "./riskRadar";


export interface IContract {
  id: string;
  clientId: string;
  name: string
}


export interface IBuilding {
  id: string;
  clientId: string;
  contractId: string;
  name: string
}

export const customQueriesAPI = omniApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({


    getAlarmsWithHistory: builder.query<{ alarms: IAlarm[] }, {
      client: string,
      building: string,
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        // prep variables
        const { client, building } = _arg;
        const results: IAlarm[] = [];
        const alarms: IAlarm[] = [];
        const alarmsRequest = await fetchWithBQ(`/client/${client}/alarms?data=${passParams({ params: { complete: 1 } })}`)
        const alarmHistoryRequests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>> = [];

        try {
          alarmsRequest.data?.data?.map((alarm: IAlarm) => {
            if (alarm.building === building) {
              alarms.push(alarm)
            }
          })
        } catch (e) { }

        alarms.map(({ id }) => {
          alarmHistoryRequests.push(
            fetchWithBQ(`/client/${client}/module/alarm/getAlarmHistory?data=${passParams({
              params: { complete: 1, alarmId: id, deviceId: 0 }
            })}`)
          )
        })

        const responses = await Promise.all(alarmHistoryRequests);
        responses.map(({ data, error, meta }) => {
          try {
            const url = meta.request.url.split('/');
            const queryString = url[9].split('=');
            const obj = JSON.parse(decodeURIComponent(queryString[1]));

            const findAlarm = alarms.find(alarm => alarm.id === obj.alarmId);

            if (findAlarm) {
              results.push({ ...findAlarm, history: data.alarmStatusChanges })
            }
          } catch (e) {
          }
        })



        return {
          data: {
            alarms: results
          }
        }
      },
    }),

    getDevicesAnnotations: builder.query<{ devices: IBoxDeviceWithInstance[] }, {
      client: string,
      devices: IBoxDeviceWithInstance[] | undefined
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        //prep variables
        const response: IBoxDeviceWithInstance[] = [];
        const deviceFieldValues: IDeviceFieldValue[] = [];
        const deviceClassLoookupFields: IDeviceClassLoookupField[] = []
        const { devices } = _arg;
        const boxInstanceIds: string[] = [];
        const fieldValuesRequests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>> = [];
        //get deviceFieldValues
        devices?.map(d => { if (!boxInstanceIds.includes(d.instance?.id!)) { boxInstanceIds.push(d.instance?.id!) } })

        devices?.map((device) => {
          fieldValuesRequests.push(fetchWithBQ(`/boxInstance/${device.instance?.id}/deviceFieldValues?data=${passParams({
            params: {
              complete: 1,
              // filters: [{ "field": "device", "value": device.id }]
            }
          })}`))
        });

        const responses = await Promise.all(fieldValuesRequests);
        responses.map(({ data, error, meta }) => {
          try {
            deviceFieldValues.push(...data?.data)
          } catch (e) {
            //error when API down?
          }
        })

        const deviceClassFieldsRequest = await fetchWithBQ(`/deviceClassFields?data=${passParams({ params: { complete: 1 } })}`)
        try {
          if (deviceClassFieldsRequest.data.status === 'success') { deviceClassLoookupFields.push(...deviceClassFieldsRequest.data.data) }

        } catch (e) { }

        devices?.map(d => {
          const tempDevice = { ...d };
          const annotations: IDeviceClassLoookupField[] = [];
          let annotatedAs: IDeviceClassLoookupField | undefined = undefined;

          if (d.deviceClass) {
            //device -> has sensors annotated -> MULTIPLE
            deviceClassLoookupFields.map(field => {
              if (field.deviceClass === d.deviceClass) {
                const deviceFieldValue = deviceFieldValues.find(item => item.device === d.id && item.deviceField === field.id);
                annotations.push({ ...field, deviceValue: deviceFieldValue?.deviceValue });
              }
            })
          } else {
            //sensor -> can be annotated at -> SINGLE
            const deviceFieldValue = deviceFieldValues.find(item => item.deviceValue === d.id);
            if (deviceFieldValue) {
              annotatedAs = deviceClassLoookupFields.find(item => item.id === deviceFieldValue.deviceField);
            }

          }
          response.push({ ...tempDevice, annotations, annotatedAs });
        })

        return {
          data: {
            devices: response
          }
        }
      },
    }),

    getDevicesBy: builder.query<{ devices: IBoxDeviceWithInstance[] }, {
      client: string,
      field: string,
      byCollectionOf: string[],
      incParent?: boolean
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { field, byCollectionOf, incParent } = _arg;
        if (byCollectionOf === undefined) return { data: { devices: [] } }
        const devices: IBoxDeviceWithInstance[] = [];
        const parentDevices: IBoxDevice[] = [];
        const boxInstancesList: IBoxInstance[] = [];
        const requests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>> = [];
        const multicallRequests: Array<{ path: string; biid: string }> = [];
        const parentRequests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>> = [];

        const boxInstances = await fetchWithBQ(`/boxInstances?data=${passParams({ params: { complete: 1, filters: [{ "field": "client", "value": _arg.client }] } })}`)

        boxInstances.data?.data?.map((boxInstance: IBoxInstance) => boxInstancesList.push(boxInstance))

        boxInstancesList.map(({ id: biid }) => {
          multicallRequests.push({ path: `/rest/dbo/boxInstance/${biid}/boxDevices`, biid })
        })

        const devicesOR = byCollectionOf.map(fieldValue => {
          return {
            field, value: fieldValue
          }
        })
        if (devicesOR.length === 0) return { data: { devices: [] } }
        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: 'POST',
          body: {
            calls: multicallRequests.map(({ path, biid }) => {
              return {
                biid,
                path,
                method: 'GET',
                data: {
                  "complete": 1,
                  filters: { 'OR': devicesOR }
                }
              }
            })
          }
        })

        multicallResponse.data?.responses?.map((response: any, index: number) => {
          if (response.status === 'success') {
            let boxId = 0;

            Object.keys(response.timings).map(key => {
              if (key.includes('boxInstance')) {
                boxId = +key.split('/')[1];
              }
            })

            const instance = boxInstancesList.find(item => item.id == boxId)
            response?.data?.map(d => {
              devices.push({ ...d, instance })
            })
          }
        })


        if (incParent) {
          //reqest parents and push on the same object!
          // parentItem
          //prep the parent requests
          devices.map(async (d) => {
            const parentId = +d?.virtualParentDevice > 0 ? d.virtualParentDevice : d.parentItem; //it this really the parent id? 

            parentRequests.push(
              fetchWithBQ(`/boxInstance/${d.instance?.id}/boxDevices?data=${passParams({
                params: { complete: 1, filters: [{ field: 'id', "value": parentId }] }
              })}`)
            )
          })

          //get the parent devices
          const parentRespones = await Promise.all(parentRequests);




          parentRespones.map(({ data, error, meta }) => {
            try {
              data?.data?.map((d: IBoxDevice) => parentDevices.push(d))
            } catch (e) {
              //error when API down?
            }
          })
        }

        //add the parent devices to the devices
        devices.map(d => {
          const parentId = +d?.virtualParentDevice > 0 ? d.virtualParentDevice : d.parentItem;

          const parentDevice = parentDevices.find(pd => pd.id === parentId)
          if (parentDevice) {
            d.parent = parentDevice;
          }
        })

        return {
          data: {
            devices
          }
        }
      },
    }),

    // xxx
    getDeviceByField: builder.query<{ devices: IBoxDevice[] }, {
      client: string
      incParent: boolean,
      field: string,
      value: string,
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { value, field } = _arg;

        const devices: IBoxDevice[] = [];
        const requests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>> = [];
        const parentRequests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>> = [];

        const boxInstances = await fetchWithBQ(`/boxInstances?data=${passParams({ params: { complete: 1, filters: [{ "field": "client", "value": _arg.client }] } })}`)
        boxInstances?.data?.data?.map((boxInstance) => {
          requests.push(
            fetchWithBQ(`/boxInstance/${boxInstance.id}/boxDevices?data=${passParams({
              params: { complete: 1, filters: [{ field, value }] }
            })}`)
          )
        })

        const responses = await Promise.all(requests);

        responses.map(response => {
          if (response?.data?.data?.length > 0) {
            response?.data?.data?.map(d => devices.push(d))
          }
        })

        return {
          data: {
            'devices': devices.length > 0 ? devices : null
          }
        }
      },
    }),

    getClientContracts: builder.query<{ contracts: Array<IContract> }, Array<string>>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const contracts: Array<IContract> = [];
        const calls: IMultiCallItem[] = [];

        _arg.map((clientId) => {
          calls.push({
            path: `/rest/dbo/client/${clientId}/contracts`,
            method: 'GET',
            data: { "complete": "1" }
          })
        })

        if (calls.length === 0) return { data: { buildings: [] } }

        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: 'POST',
          body: { calls }
        });

        multicallResponse.data?.responses?.map((response: any, index: number) => {
          if (response.status === 'success') {
            try {
              const clientId = _arg[index];
              response?.data?.map(contract => {
                contracts.push({
                  id: contract.id,
                  name: contract.name,
                  clientId
                })

              })
            } catch (e) { }
          }
        })

        return {
          data: {
            contracts
          }
        }
      },
    }),

    getClientBuildings: builder.query<{ buildings: Array<IBuildingObject> }, Array<IClient>>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const buildings: Array<IBuildingObject> = [];
        const calls: IMultiCallItem[] = [];

        _arg.map(({ id }) => {
          calls.push({
            path: `/rest/dbo/client/${id}/buildings`,
            method: 'GET',
            data: { "complete": "1" }
          })
        })

        if (calls.length === 0) return { data: { buildings: [] } }

        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: 'POST',
          body: { calls }
        });

        multicallResponse.data?.responses?.map((response: any, index: number) => {
          if (response.status === 'success') {
            try {
              const client = _arg[index];
              response?.data?.map(b => {
                buildings.push({ ...b, clientId: client.id, })
              })
            } catch (e) { }
          }
        })

        return {
          data: {
            buildings
          }
        }
      },
    }),

    getClientRoles: builder.query<{ roles: Array<IClientRole> }, Array<IClient>>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const roles: Array<IClientRole> = []
        const calls: IMultiCallItem[] = [];

        _arg.map(({ id }) => {
          calls.push({
            path: `/rest/dbo/client/${id}/clientRoles`,
            method: 'GET',
            data: { "complete": "1" }
          })
        });

        if (calls.length === 0) return { data: { roles: [] } }
        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: 'POST',
          body: { calls }
        });

        multicallResponse.data?.responses?.map((response: any, index: number) => {
          if (response.status === 'success') {
            try {
              const client = _arg[index];
              response?.data?.map(role => {
                roles.push({ ...role, client: client.id })
              })
            } catch (e) { }
          }
        })

        return {
          data: {
            roles
          }
        }
      },
    }),



    getInductionHistory: builder.query<{ history: any[] }, {
      client: string,
      buildings: IBuilding[],
      sites: IBuildingGroup[],
      user: string
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { client, buildings, sites, user } = _arg;
        const inductionInvitationsIds: any[] = [];
        const inductionIds: any[] = [];
        const history: any[] = []

        //GET HISTORY
        const fetchHistoryFilters = {
          "complete": "1",
          "filters": [
            { "field": "annualHS", "value": "1" },
            { "field": "result", "value": "passed" },
            { "field": "user", "value": user },
          ]
        }
        const { data: fetchHistory } = await fetchWithBQ(`/client/${client}/inductionHistories?data=${queryParams({ filters: fetchHistoryFilters })}`);

        fetchHistory?.data?.map(h => {
          if (inductionInvitationsIds.includes(h.inductionInvitation)) return;
          inductionInvitationsIds.push(h.inductionInvitation)
        })

        //GET INDUCTION INVITATIONS - BASED ON HISTORY
        const fetchInvitationsFilters = {
          "complete": "1",
          "filters": [
            { "field": "user", "value": user },
          ]
          // "filters": {
          //   'OR': inductionInvitationsIds.map(id => {
          //     return { "field": "id", "value": id }
          //   })
          // }
        }

        const { data: fetchInvitations } = await fetchWithBQ(`/client/${client}/inductionInvitations?data=${queryParams({ filters: fetchInvitationsFilters })}`);
        // const { data: fetchInvitations } = await fetchWithBQ(`/client/${client}/inductionInvitations`);

        fetchInvitations?.data?.map(h => {
          if (inductionIds.includes(h.induction)) return;
          inductionIds.push(h.induction)
        })

        //GET INDUCTIONS - BASED ON INVITATIONS

        const fetchInductionsFilters = {
          "complete": "1",
          // "filters": {
          //   'OR': inductionIds.map(id => {
          //     return { "field": "id", "value": id }
          //   })
          // }
        }

        // const { data: fetchInducitons } = await fetchWithBQ(`/client/${client}/inductions`);
        const { data: fetchInducitons } = await fetchWithBQ(`/client/${client}/inductions?data=${queryParams({ filters: fetchInductionsFilters })}`);



        fetchHistory?.data?.map(h => {

          const inductionInvitation = fetchInvitations?.data?.find(i => i.id === h.inductionInvitation);
          const induction = fetchInducitons?.data?.find(i => i.id === inductionInvitation?.induction);

          const tempSite = sites.find(s => s.id === induction?.site);
          const building = buildings.find(b => b.id === induction?.building);

          const siteBuildings: string[] = [];
          try {
            JSON.parse(tempSite?.buildings).map((b: string) => siteBuildings.push(b))
          } catch (e) { }
          const site = tempSite ? { ...tempSite, buildings: siteBuildings } : undefined;


          history.push({
            ...h,
            site,
            building,
            inductionInvitation,
            induction
          })
        })

        return {
          data: {
            history
          }
        }
      },
    }),

    //

    getDeviceFieldValues: builder.query<{ devices: IBoxDeviceWithInstance[] }, {
      client: string
    }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {

        const fieldValuesRequests: Array<MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>> = [];

        const { client } = _arg;
        const boxInstancesIds = [];
        const { data: boxInstancesData } = await fetchWithBQ(`/client/${client}/boxInstances?data=${passParams({ params: { complete: 1 } })}`)
        boxInstancesData?.data?.map(bi => {
          boxInstancesIds.push(bi.id)
        })

        boxInstancesIds?.map((id) => {
          fieldValuesRequests.push(fetchWithBQ(`/boxInstance/${id}/deviceFieldValues?data=${passParams({
            params: { complete: 1 }
          })}`))
        });





        // devices?.map((device) => {
        //   fieldValuesRequests.push(fetchWithBQ(`/boxInstance/${device.instance?.id}/deviceFieldValues?data=${passParams({
        //     params: {
        //       complete: 1,
        //       // filters: [{ "field": "device", "value": device.id }]
        //     }
        //   })}`))
        // });












        const fieldValues = [];

        const responses = await Promise.all(fieldValuesRequests);
        responses.map(({ data, error, meta }) => {
          fieldValues.push(...data?.data)
        })

        return {
          data: {
            data: fieldValues
          }
        }
      },
    }),


  }),
});



export const {
  useGetInductionHistoryQuery,
  useLazyGetInductionHistoryQuery,


  useGetClientContractsQuery,
  useGetClientBuildingsQuery,
  useGetClientRolesQuery,

  useGetDevicesByQuery,
  useLazyGetDevicesByQuery,
  useGetDevicesAnnotationsQuery,
  useGetAlarmsWithHistoryQuery,

  useLazyGetDevicesAnnotationsQuery,

  useGetDeviceByFieldQuery,

  useGetDeviceFieldValuesQuery,

} = customQueriesAPI;